// // imports
// import { event } from 'jquery';
// import { getKeyframeOrder } from 'three/src/animation/AnimationUtils.js';
import { cos } from 'three/examples/jsm/nodes/Nodes.js';
import * as yup from 'yup';







window.onload = () => {
    // VARS
    let loader = document.getElementById('loaderWrap')

    // json data
    let lawData = [];
    let interviewVideos = [];
    let descendingOrderLaws = [];


    //EVENT BUTTON
    let eventBtn = document.getElementById('updateBtn')
    let eventSignal = document.getElementById('signal')
    let eventTimestamp = document.getElementById('timeStamp')
    let eventLiveName = document.getElementById('liveName')
    let liveEventTrigger = document.getElementById('liveEvent')



    //HERO
    let profo = document.getElementById('profo')
    let name = document.getElementById('nameText')
    let company = document.getElementById('companyName')
    let chosenLaw = document.getElementById('chosenLaw')
    let chosenLawAmount = document.getElementById('chosenLawAmount')
    let stream = document.getElementById('stream')
    let streamSrc = document.getElementById('streamSrc')

    // INTRO counter
    let counter = document.getElementById('counterNumber').children[0]

    // DASHBOARD
    let firstLawNumber = document.getElementById('dashBoardHeadLeftTitle')
    let firstLawDescription = document.getElementById('dashboardHeadRightContent')

    let secondLaw = document.getElementById('law1')
    let thirdLaw = document.getElementById('law2')
    let fourthLaw = document.getElementById('law3')

    // FORM
    let heroCta = document.getElementById('heroCta')
    let introCta = document.getElementById('introCta')
    let submitCta = document.getElementById('submitCta')

    let formCloseBtn = document.getElementById('popUpClose');
    let formWrap = document.getElementById('popUpWrap');

    const vwpForm = document.getElementById('vwpForm');

    let step1Btn = document.getElementById('step1Btn')
    let step1 = document.getElementById('step1')
    let step2 = document.getElementById('step2')
    let step3 = document.getElementById('step3')
    let options = document.getElementsByClassName('radioBtn')
    let optionsWrap = document.getElementById('options')
    let optionsInfo = document.getElementsByClassName('optionBtn')
    let formArrows = document.getElementsByClassName('formArrow')


    let chosenOption, uploadedImg;
    // let inputs = document.getElementsByTagName('input')
    let firstNameInput = document.getElementById('firstName')
    let lastNameInput = document.getElementById('lastName')
    let companyInput = document.getElementById('company')
    let emailInput = document.getElementById('email')
    let cookieInput = document.getElementById('cookie')

    let imgLabel = document.getElementById('imgLabelTxt')
    let imgInput = document.getElementById('imageUpload')
    let btnImg = document.getElementById('btnImg');


    // INTERVIEWS
    let interviews = document.getElementById('section4')


    // LOGIC

    // FORM OPTIONS
    // Get the container where the dynamically generated components will be appended
    // Iterate through the dataArray and dynamically generate components



    // fetch laws from json
    function fetchFormData() {
        fetch('./laws.json')
            .then(response => response.json()) // Parse the response as JSON
            .then(data => {
                lawData = data.lawData
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }
    fetchFormData()


    function createFormOptions() {

        document.querySelectorAll('.option').forEach(function (element) {
            element.parentNode.removeChild(element);
        });

        lawData.forEach((item) => {
            if (!descendingOrderLaws.includes(item.id)) {
                descendingOrderLaws.push(item.id)
            }
        })
        lawData.sort((a, b) => descendingOrderLaws.indexOf(a.id) - descendingOrderLaws.indexOf(b.id));



        lawData.forEach((item, index) => {
            // OPTION CARD
            // Create a new div for each option

            const optionDiv = document.createElement("div");
            optionDiv.classList.add("option");

            // Create label element for the radio button
            const label = document.createElement("label");
            label.classList.add("optionLabel");
            label.setAttribute("for", item.id);

            // Create input element for the radio button
            const radioBtn = document.createElement("input");
            radioBtn.setAttribute("type", "radio");
            radioBtn.classList.add("radioBtn");
            radioBtn.setAttribute("id", item.id);
            radioBtn.setAttribute("name", "option");
            radioBtn.setAttribute("value", `Option ${item.id}`);
            label.appendChild(radioBtn)
            // Create div for the option image
            const optionImgDiv = document.createElement("div");
            optionImgDiv.classList.add("optionImg");

            // Create image element
            const img = document.createElement("img");

            img.setAttribute("src", item.img);
            img.setAttribute("alt", "");

            // Append image to optionImgDiv
            optionImgDiv.appendChild(img);

            // Append optionImgDiv to label
            label.appendChild(optionImgDiv);

            // Append text content to label
            const optionName = document.createElement("p")
            optionName.innerHTML = item.name
            optionName.classList.add('wordBreak')
            label.appendChild(optionName);

            // Append label to optionDiv
            optionDiv.appendChild(label);
            optionDiv.appendChild(document.createElement("br"));


            // MEER INFO BTN

            // Create anchor element for "Meer info"
            const anchor = document.createElement("a");
            anchor.setAttribute("href", "#");
            anchor.style.color = "#232323";

            // Create button element for "Meer info"
            const button = document.createElement("button");
            button.classList.add("optionBtn");
            button.setAttribute("type", "button");
            button.style.color = "#232323";

            // Create paragraph element for "Meer info"
            const p = document.createElement("p");
            p.textContent = "Meer info";

            // Append paragraph to button
            button.appendChild(p);

            // Append button to anchor
            anchor.appendChild(button);

            // Append anchor to optionDiv
            optionDiv.appendChild(anchor);

            // Append optionDiv to container
            optionsWrap.appendChild(optionDiv);

            // INFO BUTTON POP UP
            const popupWrap = document.createElement("div");
            popupWrap.classList.add("optionInfoPopupWrap")

            // Create div for popup
            const popupDiv = document.createElement("div");
            popupDiv.setAttribute("id", `popup${index + 1}`);
            popupDiv.classList.add("optionInfoPopup");
            popupDiv.style.display = "none";

            // Create div with popup content
            const poupContent = document.createElement('div')


            // Create a title for the pop up
            const popupTitle = document.createElement('h2')

            popupTitle.classList.add("optionInfoPopUpTitle")
            popupTitle.innerHTML = item.name

            // Append title button to popupDiv
            poupContent.appendChild(popupTitle)

            // Create a sub title for the pop up
            const popupSubTitle = document.createElement('h4')

            popupSubTitle.classList.add("optionInfoPopUpSubTitle")
            popupSubTitle.innerHTML = item.description

            // Append subtitle button to popupDiv
            poupContent.appendChild(popupSubTitle)

            // Create paragraph element for popup content
            const popupP = document.createElement("p");
            popupP.innerHTML = item.info;

            // Append paragraph to popupDiv
            poupContent.appendChild(popupP);

            // Append popup content to pop up div
            popupDiv.appendChild(poupContent)

            // Create a closing button for the pop up
            const popupClose = document.createElement('div')
            popupClose.innerHTML = '<p>Terug > </p>'
            popupClose.classList.add("optionInfoPopupClose")

            // Append close button to popupDiv
            popupDiv.appendChild(popupClose)


            // Append popupDiv to container
            popupWrap.appendChild(popupDiv)
            optionsWrap.appendChild(popupWrap);
        });
        addEventlistenerToInfoBtn()
        addEventlistenerToInfoCloseBtn()
    }

    createFormOptions() 

    // form arrows
  
    formArrows[0].addEventListener('click', () => {
        // console.log('left')
        optionsWrap.scrollLeft -= 150;
    })
    formArrows[1].addEventListener('click', () => {
        // console.log('right')
        optionsWrap.scrollLeft += 150;
    })
    let scrollInterval;

    formArrows[0].addEventListener('mousedown', () => {
        // console.log('left');
        scrollInterval = setInterval(() => {
            optionsWrap.scrollLeft -= 150;
        }, 100); // Adjust the interval time as needed
    });

    formArrows[1].addEventListener('mousedown', () => {
        // console.log('right');
        scrollInterval = setInterval(() => {
            optionsWrap.scrollLeft += 150;
        }, 100); // Adjust the interval time as needed
    });

    document.addEventListener('mouseup', () => {
        clearInterval(scrollInterval);
    });

    //info buttons
    function addEventlistenerToInfoBtn() {
        document.querySelectorAll('.optionBtn').forEach((button, index) => {
            button.addEventListener('click', () => {
                // Hide all popups
                document.querySelectorAll('.optionInfoPopup').forEach(popup => {
                    popup.style.display = 'none';
                });
                // Show the corresponding popup based on index
                const popup = document.getElementById(`popup${index + 1}`);
                if (popup) {
                    popup.style.display = 'flex';
                }
            });
        });
    }

    function addEventlistenerToInfoCloseBtn() {
        document.querySelectorAll('.optionInfoPopupClose').forEach((button, index) => {
            button.addEventListener('click', () => {
                const popup = document.getElementById(`popup${index + 1}`)
                popup.style.display = "none"
            });
        });
    }




    // DATABASE FETCH
    // fetch data from table
    // insert database data in front-end


    function updateData() {
        // console.log('update')
        fetch('php/fetch.php')
            .then(response => response.json()) // assuming the response is JSON
            .then(data => {
                orderLaws(data.data)
                // console.log(data); // log the received data
                insertDashboardData(data.data)
                setCounter(data.rowcount)
                setHeroContent(data.lastrow, data.amount)
                createFormOptions(descendingOrderLaws)
                // Use the data as needed
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });

        function orderLaws(data) {
            descendingOrderLaws = [];
            data.forEach((item, index) => {

                // array to determine order of the options in the form => adding the most popular options first
                descendingOrderLaws.push(Number(item.optie.split(" ")[1]))
            })
            fetchFormData()

        }

        // HERO
        // set hero content to latstet entry
        function setHeroContent(data, amount) {
            if (data.photo) {
                profo.style.display = 'block '
                profo.src = "./php/" + data.photo
            } else {
                profo.style.display = 'none'
            }
            name.innerHTML = data.voornaam + " " + data.naam
            company.innerHTML = data.company
            chosenLawAmount.innerHTML = amount.option_count + "e"

            for (let a = 0; a < lawData.length; a++) {
                if (data.optie.includes(lawData[a].id)) {
                    chosenLaw.innerHTML = `"` + lawData[a].name + `"`;
                    streamSrc.setAttribute("src", lawData[a].video)
                    stream.load();
                    // stream.play();
                }
            }

            // animateTextByLetter(name)
            // animateTextByLetter(company)
            // animateTextByLetter(chosenLaw)
            // animateTextByLetter(chosenLawAmount)

        }

        // COUNTER
        // add total amount of rows to first section counter
        function setCounter(data) {
            counter.innerHTML = data + 1000
        }

        // DASHBOARD
        // add the correct data to the dashboard
        function insertDashboardData(data) {

            firstLawNumber.innerHTML = (Number(data[0].option_count) + 550) + "x";
            if (data[1]) {
                secondLaw.children[0].innerHTML = (Number(data[1].option_count) + 150) + "x";
            } else if (!data[1]) {
                secondLaw.style.display = "none"
            }
            if (data[2]) {
                thirdLaw.children[0].innerHTML = (Number(data[2].option_count) + 150) + "x";
            } else if (!data[2]) {
                thirdLaw.style.display = "none"
            }
            if (data[3]) {
                fourthLaw.children[0].innerHTML = (Number(data[3].option_count) + 150) + "x";
            } else if (!data[3]) {
                fourthLaw.style.display = "none"
            }
            lawData.forEach(item => {
                if (data[0].optie.includes(item.id)) {
                    firstLawDescription.innerHTML = item.name;
                }
                else if (data[1] && data[1].optie.includes(item.id)) {
                    secondLaw.children[1].innerHTML = item.name;
                } else if (data[2] && data[2].optie.includes(item.id)) {
                    thirdLaw.children[1].innerHTML = item.name;
                } else if (data[3] && data[3].optie.includes(item.id)) {
                    fourthLaw.children[1].innerHTML = item.name;
                }
            })
            // for (let z = 0; z < lawData.length; z++)
            //     console.log(z)
            //     if (data[0].optie.includes(lawData[z].id)) {
            //         firstLawDescription.innerHTML = "hallo";
            //         // firstLawDescription.innerHTML = lawData[z].name;
            //     } 
            // else if (data[1] && data[1].optie.includes(lawData[z].id)) {
            //     secondLaw.children[1].innerHTML = lawData[z].name;
            // } else if (data[2] && data[2].optie.includes(lawData[z].id)) {
            //     thirdLaw.children[1].innerHTML = lawData[z].name;
            // } else if (data[3] && data[3].optie.includes(lawData[z].id)) {
            //     fourthLaw.children[1].innerHTML = lawData[z].name;
            // }
        }
        // let xhr = new XMLHttpRequest();
        // xhr.open("GET", "php/unprint.php", true); // Assuming update_isPrinting.php is the PHP file to handle the update
        // xhr.send();
        // console.log('all loaded')
        setTimeout(() => {
            loader.style.display = 'none'
        }, 500);


    }
    updateData()

    // TEXT ANIMATION
    // function animateTextByLetter(divId) {
    //     const div = divId;
    //     const text = div.innerHTML;
    //     div.innerHTML = ''; // Clear the div

    //     // Calculate total width of the text
    //     const totalWidth = div.getBoundingClientRect().width;

    //     // Loop through each letter in the text
    //     for (let i = 0; i < text.length; i++) {
    //         setTimeout(() => {
    //             // Create span element for each letter
    //             const letters = '#$%&()*+,-./0123456789:;<=>?@ABCDEFGHIJKLMNOPQRSTUVWXYZ[]^_`abcdefghijklmnopqrstuvwxyz{|}~';
    //             const span = document.createElement('span');
    //             span.textContent = text[i];
    //             div.appendChild(span);

    //             // Set width of the div to total width
    //             div.style.width = `${totalWidth}px`;

    //             let counter = 0;
    //             const interval = setInterval(() => {
    //                 if (counter < 20) { // Change 20 to control the duration of rapid animation
    //                     // Randomly change letters during animation
    //                     const randomLetter = letters.charAt(Math.floor(Math.random() * letters.length));
    //                     span.textContent = randomLetter;
    //                     counter++;
    //                 } else {
    //                     clearInterval(interval);
    //                     span.textContent = text[i]; // Replace 'YourDesiredLetter' with the desired letter
    //                 }
    //             }, 100 * counter);

    //         }, i * 100)
    //     }
    // }


    // FORM

    // CTA's top opne form
    heroCta.addEventListener('click', () => { openForm() })
    introCta.addEventListener('click', () => { openForm() })
    // open form function
    function openForm() {
        formWrap.classList.remove('hide')
    }

    // Form close button
    formCloseBtn.addEventListener('click', () => {
        formWrap.classList.add('hide')
        step1.classList.remove('hide')
        step2.classList.add('hide')
        step3.classList.add('hide')
        btnImg.src = './img/btn.png';
        // reset the form
        removeElementsWithErrorClass()
        vwpForm.reset()
        // updateData()
        // remove all active options styling
        for (let x = 0; x < options.length; x++) {
            if (options[x].parentElement.parentElement.classList.contains('active')) {
                options[x].parentElement.parentElement.classList.remove('active')
            }
        }
        // location.reload();
    })

    // STEP 1

    // Options styling when selected => primary color
    for (let i = 0; i < options.length; i++) {
        options[i].addEventListener('click', () => {
            for (let x = 0; x < options.length; x++) {
                if (options[x].checked) {
                    options[x].parentElement.parentElement.classList.add('active')
                } else if (options[x].parentElement.parentElement.classList.contains('active')) {
                    options[x].parentElement.parentElement.classList.remove('active')
                }
            }
        })
    }

    // check if an option is selected
    function checkOptions() {
        for (let i = 0; i < options.length; i++) {
            if (options[i].checked) {
                chosenOption = options[i].value
                return true
            }
        }
        return false
    }

    // Open next form step if option is selected
    step1Btn.addEventListener('click', () => {
        if (checkOptions()) {
            step1.classList.add('hide')
            step2.classList.remove('hide')
        } else {
            alert("selecteer een optie")
        }
    })


    // STEP 2

    // image check: when file is uploaded check file and bind it to uploadedImg
    imgInput.addEventListener('change', (event) => {
        uploadedImg = event.target.files[0]
        previewImage(event)
    });

    function previewImage(event) {
        const reader = new FileReader();
        reader.onload = function () {
            btnImg.src = reader.result;
        }
        reader.readAsDataURL(event.target.files[0]);
    }

    // // FORM VALIDATION  + SUBMIT

    // cta click handle
    submitCta.addEventListener('click', (e) => {
        e.preventDefault()

        // FORM INPUT
        const formData = {
            option: chosenOption,
            image: uploadedImg,
            firstName: firstNameInput.value,
            lastName: lastNameInput.value,
            company: companyInput.value,
            email: emailInput.value,
            cookie: cookieInput.checked
        }
        validateForm(formData)
    })

    // form validation properties
    const schema = yup.object().shape({
        firstName: yup.string().required('Voornaam is verplicht'),
        lastName: yup.string().required('Achternaam is verplicht'),
        company: yup.string().required('Bedrijfsnaam is verplicht'),
        email: yup.string().email().required("Vul een geldig email adres in"),
        cookie: yup.bool().oneOf([true], 'verplicht' + " ")
        // image: yup.mixed().test('is-image', 'Het bestand moet een afbeelding zijn', (value) => {
        //     if (!value) return false; // handle empty files
        //     console.log(value)
        //     const allowedFormats = ['image/jpeg', 'image/png']; // Define allowed image formats
        //     return value && allowedFormats.includes(value.type); // Check if the file type is in the allowed formats
        // }),
    });

    // remove exisiting errors
    function removeElementsWithErrorClass() {
        // Select all elements with class name 'error'
        var errorElements = document.querySelectorAll('.error');
        // console.log(errorElements)
        // Loop through each error element
        errorElements.forEach(function (element) {
            // Remove the element from the DOM
            element.parentNode.removeChild(element);
        });
    }


    // FORM INPUT VALIDATION
    async function validateForm(formData) {
        // Call the function to remove elements with class name 'error'
        removeElementsWithErrorClass();
        try {
            await schema.validate(formData, { abortEarly: false });
            // console.log('Data is valid');
            // If validation succeeds, show success message
            // Construct the form data
            const dataToSend = new FormData();
            dataToSend.append('option', chosenOption);
            if (uploadedImg) {
                dataToSend.append('image', uploadedImg);
            }
            dataToSend.append('firstName', firstNameInput.value);
            dataToSend.append('lastName', lastNameInput.value);
            dataToSend.append('company', companyInput.value);
            dataToSend.append('email', emailInput.value);
            dataToSend.append('cookie', cookieInput.checked ? 1 : 0);

            // Send the form data using Fetch API
            fetch('php/submit.php', {
                method: 'POST',
                body: dataToSend
            })
                .then(response => {
                    // Handle response
                    //console.log(response)
                    showSuccessMessage(dataToSend)
                    showDataLive(chosenOption)
                })
                .catch(error => {
                    // Handle error
                    //console.log(error)
                });
        } catch (error) {
            if (error instanceof yup.ValidationError) {
                error.inner.forEach(errorMessage => {
                    // console.log(errorMessage.errors[0], errorMessage.path)
                    let message = errorMessage.errors[0];
                    let messagePath = errorMessage.path;
                    // console.log(message, messagePath)
                    const errorElement = document.createElement('div');
                    errorElement.textContent = message;
                    errorElement.style.color = 'red';
                    errorElement.classList.add('error');
                    if (messagePath !== 'image') {
                        const inputField = vwpForm.querySelector(`[name="${messagePath}"]`);
                        inputField.insertAdjacentElement('afterend', errorElement);
                    } else if (messagePath === 'image') {
                        //console.log('image', imgLabel, errorElement)
                        imgLabel.insertAdjacentElement('afterend', errorElement);
                    }
                });
            }
        }
    };

    // succes message function
    function showSuccessMessage(data) {
        // console.log(data)
        // console.log('succes')

        updateData()
        // reset form to first step
        step2.classList.add('hide')
        step3.classList.remove('hide')
    }



    // Set video carousel
    // videos

    // fetch laws from json
    fetch('./interviews.json')
        .then(response => response.json()) // Parse the response as JSON
        .then(data => {
            interviewVideos = data.interviews
            // console.log(data.interviews[0].name)
            if (!data.interviews[0].name.includes("Politieker")) {
                interviews.style.display = "block"
                setInterviewVideos()
            }

        })
        .catch(error => {
            console.error('Error:', error);
        });

    function setInterviewVideos() {
        const bigVideo = document.getElementById('video');
        const bigVideoTitle = document.getElementById('videoTitle');
        const thumbnailVideosContainer = document.getElementById('thumbWrap');

        // // 2. Set the big video source to the randomly selected video
        let randomNum = Math.floor(Math.random() * interviewVideos.length)
        const randomVideo = interviewVideos[randomNum].video;
        bigVideo.src = randomVideo;
        // // 3. Update big video title accordingly
        bigVideoTitle.textContent = `Interview met ${interviewVideos[randomNum].name} `; // Example title

        function showBigVideo(videoSrc, title) {
            bigVideo.src = videoSrc;
            bigVideoTitle.textContent = title;
        }

        // set thumbnail videos
        function addThumbnailVideos(startIndex) {
            thumbnailVideosContainer.innerHTML = ''; // Clear existing thumbnails
            const endIndex = Math.min(startIndex + 3, interviewVideos.length);
            for (let i = startIndex; i < endIndex; i++) {
                const videoSource = interviewVideos[i].video;
                const thumbnailDiv = document.createElement('div');
                thumbnailDiv.className = 'thumbnail';
                thumbnailDiv.innerHTML = `
            <video class="thumbnailVideo" src="${videoSource}" ></video>
            <p class="thumbVideoTitle">${interviewVideos[i].name}</p>`;
                thumbnailDiv.addEventListener('click', function () {
                    showBigVideo(videoSource, `Interview met ${interviewVideos[i].name} `);
                });
                thumbnailVideosContainer.appendChild(thumbnailDiv);
            }
        }

        let startIndex = 0;
        addThumbnailVideos(startIndex);

        document.getElementById('prevBtn').addEventListener('click', function () {
            if (startIndex > 0) {
                startIndex -= 1;
                addThumbnailVideos(startIndex);
            }
        });

        document.getElementById('nextBtn').addEventListener('click', function () {
            if (startIndex + 3 < interviewVideos.length) {
                startIndex += 1;
                addThumbnailVideos(startIndex);
            }
        });
    }


    // // LIVE EVENT TRIGGER
    // // check database for update
    // let currentID = 0;



    // setInterval(() => {
    //     fetch('php/fetch.php')
    //         .then(response => response.json()) // assuming the response is JSON
    //         .then(data => {
    //             // console.log(data); // log the received data
    //             // console.log(currentID, data.lastrow.id)
    //             // if (data.lastrow.id !== currentID) {
    //             //     console.log('update')
    //             // }
    //             // console.log(currentID, data.lastrow)
    //             if (currentID != data.lastrow.id) {
    //                 // console.log('hi')
    //                 if (currentID != 0) {
    //                     // console.log(data.lastrow)
    //                     eventSignal.innerHTML = data.lastrow.optie
    //                     eventTimestamp.innerHTML = data.lastrow.datum
    //                     eventLiveName.innerHTML = data.lastrow.id + " " + data.lastrow.naam
    //                     eventLiveName.style.color = "red"
    //                     // optionToPrint.innerHTML = data.lastrow.option
    //                 }
    //                 currentID = data.lastrow.id
    //             }

    //             // console.log(data.lastrow.isPrinting)
    //             if (data.lastrow.isPrinting == 1) {
    //                 // console.log('time to update')
    //                 updateData()
    //                 // let xhr = new XMLHttpRequest();
    //                 // xhr.open("GET", "php/unprint.php", true); // Assuming update_isPrinting.php is the PHP file to handle the update
    //                 // xhr.send();
    //             }

    //             // Use the data as needed
    //         })
    //         .catch(error => {
    //             console.error('Error fetching data:', error);
    //         });
    // }, 2000)



    // // event listener update button

    // const urlParams = new URLSearchParams(window.location.search);

    // eventBtn.addEventListener('click', () => {
    //     // console.log('click')
    //     let xhr = new XMLHttpRequest();
    //     xhr.open("GET", "php/print.php", true); // Assuming update_isPrinting.php is the PHP file to handle the update
    //     xhr.send();
    //     eventLiveName.style.color = "black"
    // })

    // document.addEventListener("keydown", function (event) {
    //     // Check if CTRL (key: 'Control') and U (key: 'u') are pressed simultaneously
    //     if (event.ctrlKey && event.key === 'u' && urlParams.has('live')) {
    //         let xhr = new XMLHttpRequest();
    //         xhr.open("GET", "php/print.php", true); // Assuming update_isPrinting.php is the PHP file to handle the update
    //         xhr.send();
    //         eventLiveName.style.color = "black"
    //     }
    // });




    // // Check if the 'live' parameter exists to open live event dashboard
    // if (urlParams.has('live')) {
    //     // 'live' parameter exists in the URL
    //     // Your code here
    //     liveEventTrigger.style.display = 'block'


    // }
}
